<template>
  <div class="PracticeTestRecords" style="margin: -15px -15px">
    <div class="Practice_left">
      <div class="Practice_module">模块</div>
      <a-menu style="width: 100%" v-model="selectId" mode="inline">
        <a-menu-item
            v-for="(item, index) in templateList"
            @click="selectStatus(item.id,item.name)"
            :key="item.id"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
      <div style="display: flex;justify-content: right;padding: 5px">
        <a-pagination
            size="small"
            v-model="temPosition.pageNum"
            :total="temPosition.total"
            @change="menuPageChange"/>
      </div>
    </div>
    <div class="Practice_right">
      <div class="Practice_right_top">
        <div class="Practice_right_title">用户每日完成套数</div>
        <div>
          <div class="top_cont">
            <div class="EduRec-top-input">
              <span>日期：</span>
              <a-range-picker v-model="datePicker" style="margin-right: 15px;width: 220px" @change="onChange" />
            </div>
            <div class="EduRec-top-input">
              <a-button style="margin-right: 20px" @click="exportModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出该模块
              </a-button>
              <a-button  @click="exportAllModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出全部模块
              </a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
          <a-table
              @change="orderChange"
              :rowKey="record=>record.id"
              :pagination="false"
              :columns="columns"
              :data-source="data">
          <span slot="location" slot-scope="text, record">
          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          :page-size-options="pageSizeOptions"
                          :page-size.sync="pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="showSizeChange"
                          @change="pageChange" />
          </div>
        </div>
      </div>

      <div class="Practice_right_bottom">
        <div class="Practice_right_title">模拟考试每日完成用户数</div>
        <div>
          <div class="top_cont">
            <div class="EduRec-top-input">
              <span>日期：</span>
              <a-range-picker v-model="datePickerPrevious" style="margin-right: 15px;width: 220px" @change="PracticeOnChange" />
            </div>
            <div class="EduRec-top-input">
              <a-button style="margin-right: 20px" @click="Practice_exportModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出该模块
              </a-button>
              <a-button  @click="Practice_exportAllModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出全部模块
              </a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
          <!--            :rowKey="record=>record.id"   -->
          <a-table
              :rowKey="record=>record.id"
              @change="orderPracticeChange"
              :pagination="false"
              :columns="Practice_columns"
              :data-source="Practice_data">
           <span slot="makeName" slot-scope="text, record">

            <span v-if="record.examExeType">{{record.examExeType}} -</span> {{record.name}}

          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ Practice_pagination.total }} 条记录 第 {{
              Practice_pagination.current
            }} / {{ Math.ceil(Practice_pagination.total / Practice_pagination.pageSize) }} 页</span>
            <a-pagination v-model="Practice_pagination.current"
                          :total="Practice_pagination.total"
                          :page-size-options="pageSizeOptions"
                          :page-size.sync="Practice_pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="Practice_showSizeChange"
                          @change="Practice_pageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  getModuleMenu,
  getMockUser,
  getExamMockDay,
  examMockDayExport,
  examMockDayUserExport,
} from "@/service/titleExam_api";

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "6%"
  },{
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    align: 'center',
    ellipsis: true,
    width:'20%'
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    align: 'center',
    width:'8%'
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },
  {
    title: '完成套数',
    dataIndex: 'mockCount',
    key: 'mockCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
const Practice_columns = [
  {
    title: '模拟考试名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "20%",
    scopedSlots: { customRender: 'makeName' },
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },{
    title: '使用人数',
    dataIndex: 'userCount',
    key: 'userCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
  {
    title: '完成次数',
    dataIndex: 'haveCount',
    key: 'haveCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
let data = [];
const Practice_data = [];
export default {
  name: "PracticeTestRecords",
  data(){
    return{
      selectId: [],
      selectTemName:'',
      templateList: [],
      pagination:{
        total:0,
        pageSize: 5,
        current:1,
      },
      spinning:false,
      data,
      Practice_data,
      columns,
      Practice_columns,
      startTime:null,
      endTime:null,
      Practice_pagination:{
        total:0,
        pageSize: 5,
        current:1,
      },
      Practice_startTime:null,
      Practice_endTime:null,
      temPosition:{
        pageNum:1,
        pageSize:15,
        total:0,
      },
      order:null,
      orderPractice:null,

      datePicker:null,
      datePickerPrevious:null,

      pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
    }
  },
  async created() {
    await this.getLeftTemplate(); //获取左侧菜单列表
    await this.NumberQuestionsDay(); //模拟考试-用户每日完成数量
    await this.NumberUsersPracticeExercises(); //同步练习记录-每日使用人数
  },
  methods:{
    async menuPageChange(current, pageSize) {
      this.temPosition.pageNum = current;
      await this.getLeftTemplate();
      await this.NumberQuestionsDay(); //模拟考试-用户每日完成数量
      await this.NumberUsersPracticeExercises(); //同步练习记录-每日使用人数
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      let data = {
        page:this.temPosition.pageNum,
        pageSize:this.temPosition.pageSize
      }
      const res = await getModuleMenu(data);
      if (res.code === 200) {
        this.templateList = res.data;
        this.selectId[0] = res.data[0].id;
        this.selectTemName = res.data[0].name;
        this.temPosition.total = res.count;
      }
    },
    //获取右侧  模拟考试-用户每日完成数量
    async NumberQuestionsDay() {
      let data = {
        board:'MOCK', //MOCK模拟考试
        typeId:this.selectId[0], //模块id
        startTime:this.startTime,
        endTime:this.endTime,
        curPage:this.pagination.current,
        pageSize:this.pagination.pageSize,
        sortType:this.order, //mock_up模拟题完成数量升序排序，mock_down降序
      }
      const res = await getMockUser(data);
      if (res.statuscode === 200) {
        this.data = res.data;
        this.pagination.total = res.count;
      }
    },
    //获取右侧  模拟考试-每日完成用户数
    async NumberUsersPracticeExercises() {
      let data = {
        board:'MOCK',
        typeId:this.selectId[0], //模块id
        startTime:this.Practice_startTime,
        endTime:this.Practice_endTime,
        curPage:this.Practice_pagination.current,
        pageSize:this.Practice_pagination.pageSize,
        sortType:this.orderPractice, //question_up完成次数升序，question_dwon完成次数降序，user_up使用人数升序，user_down使用人数降序
      }
      const res = await getExamMockDay(data);
      if (res.code === 0) {
        this.Practice_data = res.data;
        this.Practice_pagination.total = res.count;
      }
    },
    // 第一个表格排序
    orderChange(page, filters, sorter) {
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      if(sorter.columnKey === 'mockCount'){ // 完成题目数量

        if(sorter.order === 'ascend'){ //降序

          this.order = 'mock_down';

        }else if(sorter.order === 'descend'){ //降序

          this.order = 'mock_up';

        }else {
          this.order = null;
        }
        this.pagination.current = 1;
        this.NumberQuestionsDay();
      }
    },
    // 第二个表格排序
    orderPracticeChange(page, filters, sorter){
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      if(sorter.columnKey === 'userCount'){ //使用人数
        if(sorter.order === 'ascend'){ //升序

          this.orderPractice = 'user_down';

        }else if(sorter.order === 'descend'){ //降序

          this.orderPractice = 'user_up';

        }else {
          this.orderPractice = null;
        }
      }else if(sorter.columnKey === 'haveCount'){ //完成次数
        if(sorter.order === 'ascend'){ //升序

          this.orderPractice = 'question_down';

        }else if(sorter.order === 'descend'){ //降序

          this.orderPractice = 'question_up';

        }else {
          this.orderPractice = null;
        }
      }
      this.Practice_pagination.current = 1;
      this.NumberUsersPracticeExercises();
    },
    selectStatus(value,name) {
      this.selectId[0] = value;
      this.selectTemName = name;
      this.pagination.current = 1;
      this.pagination.pageSize = 5;

      this.Practice_pagination.current = 1;
      this.Practice_pagination.pageSize = 5;

      this.startTime = null;
      this.endTime = null;
      this.Previous_startTime = null;
      this.Previous_endTime = null;
      this.datePicker = null;
      this.datePickerPrevious = null;

      //切换菜单右侧列表切换内容
      this.NumberQuestionsDay();
      this.NumberUsersPracticeExercises();
    },

    showSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
      this.NumberQuestionsDay();
    },
    async exportModule() {
      let ExelName = this.selectTemName;
      let data = {
        board:'MOCK',
        startTime: this.startTime,
        endTime: this.endTime,
        typeId:this.selectId[0],
        sortType:this.order,
        ExelName: ExelName
      }
      await examMockDayUserExport(data);
    },
    async exportAllModule() {
      let ExelName = '模拟考试使用记录-用户每日完成套数';
      let data = {
        board:'MOCK',
        ExelName: ExelName,
        sortType:this.order,
      }
      await examMockDayUserExport(data);
    },



    Practice_showSizeChange(current, pageSize) {
      this.Practice_pagination.current = current;
      this.Practice_pagination.pageSize = pageSize;
      this.NumberUsersPracticeExercises();
    },
    Practice_pageChange(pageNum,pageSize){
      this.Practice_pagination.current = pageNum;
      this.Practice_pagination.pageSize = pageSize;
      this.NumberUsersPracticeExercises();
    },
    PracticeOnChange(date, dateString) {
      this.Practice_startTime = dateString[0];
      this.Practice_endTime = dateString[1];
      this.Practice_pagination.current = 1;
      this.NumberUsersPracticeExercises();
    },
    async Practice_exportModule() {
      let ExelName = this.selectTemName;
      let data = {
        board:'MOCK',
        startTime: this.Practice_startTime,
        endTime: this.Practice_endTime,
        typeId: this.selectId[0],
        sortType: this.orderPractice,
        ExelName: ExelName
      }
      await examMockDayExport(data);
    },
    async Practice_exportAllModule() {
      let ExelName = '模拟考试每日完成用户数';
      let data = {
        board:'MOCK',
        sortType: this.orderPractice,
        ExelName: ExelName
      }
      await examMockDayExport(data);
    },
  }
}
</script>

<style scoped lang="scss">
.ant-menu{
  border: none;
}
.PracticeTestRecords{
  min-height: 110%;
  display: flex;
  justify-content: space-between;
  .Practice_left{
    width: 20%;
    //background-color: #ee7474;
    .Practice_module{
      margin-left: 25px;
      margin-top: 10px;
    }

  }
  .Practice_right{
    border-left: 15px solid #F0F2F5;
    width: 80%;
    .Practice_right_top{
      border-bottom: 15px solid #F0F2F5;
    }
    .Practice_right_title{
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }
    .top_cont{
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .EduRec-top-input{
      height: 50px;
      padding-top: 15px;
    }

    .activity-table{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
    .pageView{
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .pageLeft{
      font-size: 15px;color: #929292
    }
  }
}
</style>
